.company-banner {
  position: relative;
  background: url("../images/sigiriya.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 10%;
  min-height: 600px;
}

.left-gradient {
  /* position: absolute; */
  background: url("../images/01.png");
  background-repeat: no-repeat;
  background-size: 370px 100%;
  background-position: left;
  min-height: 600px;
}

.company-banner .intro {
  background-color: #ffffffd8;
  border-radius: 15px;
  margin-bottom: 15px;
}

.sidebar-productList {
  padding-top: 30px;
  padding-left: 40px;
  background: white;
  min-height: 400px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 7%);
  position: sticky;
  top: 80px;
}
.sidenav-burgerMenu{
  position: sticky;
  top: 80px;
  margin-top: 10px;
  margin-left: 10px;
}
.sidebar-productList p {
  cursor: pointer;
}
.sidebar-productList p:hover {
  transform: translate(5px,0);
}
.sidebar-productList img {
  width: 90%;
}
.sticky--productList {
  position: fixed;
  top: 0;
  width: 100%;
}

@media screen and (min-width: 767px) {
  .company-banner .intro {
    margin-top: 70px;
  }
}

.company-banner p {
  color: #3551a3;
  text-align: justify;
  font-size: 1.1em;
  margin-bottom: 0px;
  font-weight: 600;
}

.company-banner h1 {
  position: absolute;
  bottom: 0px;
  padding-bottom: 30px;
  padding-left: 30px;
  font-size: 100px;
  color: #ffffff;
  text-shadow: 2px 2px 4px #000000;
  margin: 0px;
}

@media screen and (max-width: 767px) {
  .company-banner h1 {
    position: relative;
    padding: 15px 15px 15px 0px;
    font-size: 50px;
  }

  .left-gradient {
    background: none;
  }

  .logo-bg {
    margin-top: 60px !important;
  }
}

.logo-bg {
  display: inline-block;
  background-color: #ffffffa8;
  border-radius: 15px;
  padding: 10px 15px 10px 15px;
  margin-top: 15px;
}

.logo-bg img {
  width: 75px;
  height: 91px;
}

.products .prod-list-card {
  transition: transform .2s;
}

.products .prod-list-card:hover {
  cursor: pointer;
  transform: scale(1.05);
}
