.aboutUsTitle {
  --bs-gutter-x: 0;
  padding-left: 3%;
}

.productTitle {
  --bs-gutter-x: 0;
  padding-right: 3%;
}
.aboutUsTitle h1 {
  padding: 1rem;
  color: #ffffff;
  font-weight: bold;
  background: -webkit-linear-gradient(180deg, #083a51 88%, #7cc242 40%);
  text-align: center;
  margin-bottom: 0;
  border-top-left-radius: 180px 180px;
}

.productTitle h1 {
  padding: 1rem;
  color: #ffffff;
  font-weight: bold;
  background: -webkit-linear-gradient(0deg, #083a51 88%, #7cc242 40%);
  text-align: center;
  margin-bottom: 0;
  border-top-right-radius: 180px 180px;
}

.about-us {

  background: url('../images/04.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  min-height: 800px;
}

.about-us .intro {
  background-color: #7cc242;
  border-radius: 15px;
}

.about-us h1, .products h1 {
  color: #3551a3;
  font-weight: bold;
}

.about-us p {
  color: #ffffff;
  text-align: justify;
  font-size: 1.1em;
  margin-bottom: 0px;
}

.main-wrapper{
  background-size: 370px 100%;
  min-height: 600px;
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
    font-size: 18px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }  
  
  .slide-image {
    /* margin-top: 10%; */
    /* margin-left: 5%; */
    margin-right: 50%;
    /* margin-bottom: 5%; */
    display: block;
    width: 90%;
    height: 100%;
    transform: scaleX(-1) ;
  }

  .slide-logo {
    /* margin-top: 10%; */
    /* margin-left: 10%;
    margin-right: 50%;
    margin-bottom: 5%; */
    display: block;
    width: 180px;
    height: 60px;
    background-color: #ffffffb7;
    border-radius: 10px;
    padding: 5px;
  }
 

  .slide-bg{
    /* background-image: url('../images/tree1.png'); */
    background-position: center center;
    height: 100%;
    animation: changeBg 20s infinite;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @keyframes changeBg
{
   0%   {background-image: url('../images/sigiriya.jpg');}
   50%  {background-image: url('../images/tree1.png');}
   75% {background-image: url('../images/sea1.png');}
   100%   {background-image: url('../images/sigiriya.jpg');}
}

/* Slide Description */
.slide-details {
  /* margin-top: 10%; */
  padding-left: 20%;
  margin-bottom: 8%;
}

.sliderow {
  margin-top: 10%;
}


.slide-description {
  /* margin-bottom: 20px; */
}
.slide-description h1 {
  font-weight: 700;
  /* font-family: Arial, Helvetica, sans-serif; */
  text-shadow: 0px 5px 5px rgba(43, 44, 44, 0.711);
  font-size: 3em; 
  color: #f9fbfc;
  letter-spacing: 2px;
  margin: 0px;
}
.slide-description h3 {
  font-size: 1.6em;
  font-weight: 500;
  /* font-family: Arial, Helvetica, sans-serif; */
  color: #fbfdff;
  line-height: 24px;
  letter-spacing: 2px;
  text-align: left;
  text-shadow: 0px 5px 5px rgba(43, 44, 44);
}
  .left-grad{
      /* position: absolute; */
  background: url('../images/01.png');
  background-repeat: no-repeat;
  background-size: 370px 100%;
  background-position: left;
  min-height: fit-content;
  }

  .logo{
    width: 100%;
  }

  .logo-wrapper{
    height: 75px;
    width: 75px;
    position: relative;
    background-color: #ffffffa8;
    border-radius: 15px;
    text-align: center;
    margin-top: 25%;
    padding: 10px 15px 10px 15px;
    z-index: 100;
  }

  .logo-wrapper:hover {
    background-color: #ffffffdc;
  }

  .logo-col{
    height: fit-content;
    /* border: 2px solid black; */
  }

  .logo-row{
    padding-top: 20px;
    padding-left: 10px;
    height: fit-content;
    /* border: 2px solid red; */
  }

  @media screen and (max-width: 1200px) {
    .logo{
      width: 100%;
    }
    .logo-wrapper{
      height: 75px;
      width: 75px;
    }

  }

  
  @media screen and (max-width: 1024px) {
    .logo{
      width: 100%;
    }
    .logo-wrapper{
      height: 65px;
      width: 65px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .slide-image {
      /* margin-top: 5%; */
      margin-left: 15%;
      width: 60%;
      height: 90%;
    }

    .slide-logo {
      /* margin-top: 10%; */
      /* margin-left: 10%;
      margin-right: 50%;
      margin-bottom: 5%; */
      display: block;
      width: 80px;
      height: 40px;
    }

    /* Slide Description */
  .slide-details {
    padding-left: 20%;
  }


  .slide-description {
    /* margin-bottom: 20px; */
  }
  .slide-description h1 {
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 0px 5px 5px rgba(43, 44, 44, 0.711);

    font-size: 38px;
    color: #e9eef3;
    letter-spacing: 2px;
  }
  .slide-description h3 {
    font-size: 22px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    color: #f5faff;
    line-height: 24px;
    letter-spacing: 2px;
    text-align: left;
    text-shadow: 0px 5px 5px rgba(43, 44, 44);
  }

}

  @media screen and (max-width: 768px) {
    .left-grad {
      background: none;
    }
    .logo{
      width: 100%;
    }
    .logo-wrapper{
      height: 50px;
      width: 50px;
      padding: 5px 10px 5px 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 10px;
    }

    .swiper-image {
      /* margin-top: 20%; */
      margin-left: 15%;
      width: 70%;
      height: 90%;
    }

    /* Slide Description */
.slide-details {
  padding-left: 20%;
}


.slide-description {
  /* margin-bottom: 20px; */
}
.slide-description h1 {
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 0px 5px 5px rgba(43, 44, 44, 0.711);

  font-size: 25px;
  color: #e9eef3;
  letter-spacing: 2px;
}
.slide-description h3 {
  font-size: 18px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: #f5faff;
  line-height: 24px;
  letter-spacing: 2px;
  text-align: left;
  text-shadow: 0px 5px 5px rgba(43, 44, 44);
}
  }

@media screen and (max-width: 480px) {
    .left-grad {
      background: none;
    }
    .logo{
      width: 100%;
    }
    .logo-wrapper{
      height: 50px;
      width: 50px;
      padding: 5px 10px 5px 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 10px;
    }

    .swiper-image {
      /* margin-top: 20%; */
      /* margin-left: 5%; */
      width: 90%;
      height: 90%;
    }

      /* Slide Description */
    .slide-details {
      /* margin-top: 20%; */
      padding-left: 12%;
    }

    .sliderow {
      margin-top: 20%;
    }


    .slide-description {
      /* margin-bottom: 20px; */
    }
    .slide-description h1 {
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif;
      text-shadow: 0px 5px 5px rgba(43, 44, 44, 0.711);

      font-size: 18px;
      color: #e9eef3;
      letter-spacing: 2px;
    }
    .slide-description h3 {
      font-size: 12px;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
      color: #f5faff;
      line-height: 24px;
      letter-spacing: 2px;
      text-align: left;
      text-shadow: 0px 5px 5px rgba(43, 44, 44);
    }
    
}

@media screen and (max-width: 400px) {
  .slide-description {
    padding-left: 5px;
  }
}
h1
.card {
  border-radius: 10px;
  border-width: 0px;
  /* box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26); */
}

.card-body {
  background-color: #3551a3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title {
  color: #ffffff;
}

.card-img-top {
  /* width: 100%; */
  height: 40vh;
  object-fit: contain;
}

.card-bg {
  background: url('../images/card_bg_1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
