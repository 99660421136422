.spacer {
  flex: 1 1 auto;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1.5rem;
  height: fit-content;
  text-align: right;
  font-size: 16pt;
	background: linear-gradient(to right, rgba(52, 152, 219, 0), rgba(98, 118, 145, 0.5));
  z-index: 100;
  position: fixed !important;
  width: 100%;
}

.navbar-light .navbar-nav .nav-link {
  color: #f7f7f7 !important;
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.55);
  border-color: rgba(0,0,0,.1);
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: black;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow .15s ease-in-out;
}

.navbar-collapse {
  height: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  overflow: visible;
  z-index: 100000;
  height: fit-content;
  /* background: linear-gradient(to right, rgba(52, 152, 219, 0.1), rgba(98, 118, 145, 0.8)); */
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 30px;
    margin-bottom: 0;
    list-style: none;
}

nav a.active {
  font-weight: bold;
}



.brand-strip {
  background: rgb(124, 194, 66);
  background: linear-gradient(270deg, rgba(124, 194, 66, 0.09567577030812324) 40%, rgba(53, 81, 163, 1) 100%);
  border-radius: 9px;
  color: white;
  padding: 5px;
  padding-left: 15px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 3px;
}
.contactus-container {
 
  color: #3551a3;

  overflow: hidden;
}
.contactus-container iframe{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.contactus-container a{
 text-decoration: none;
 color: #3551a3;
}
.contactus-form {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  box-shadow: rgba(139, 139, 139, 0.24) 0px 3px 8px;
  padding: 40px;
}
.contactus-form button {
  width: 120px;
  background: #3551a3 !important;
  font-style: italic;
  border: none;
}
.used-tag{
  position: absolute;
  top: -12px;
  left: -2px;
  height: 120px;
}

.company h1 {
  text-shadow: 0px 7px 7px rgba(43, 44, 44, 0.711);
  color: #ffffff;
  font-weight: 700;
}